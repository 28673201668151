<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          @click="openPreview"
          :disabled="!photo"
          v-on="on"
        >
          <v-icon>fa fa-file-image</v-icon>
        </v-btn>
      </template>

      <span>{{'action.view'| t}}</span>
    </v-tooltip>

    <photo-viewer
      v-if="viewerPhoto"
      @click.stop
      ref="photoViewer"
      :photo="viewerPhoto"
    ></photo-viewer>

<!--    <photoSizeListSelection-->
<!--      :photo="photo"-->
<!--      :choice="choice"-->
<!--      v-model="bottomSheetActive"-->
<!--    ></photoSizeListSelection>-->
  </div>
</template>

<script lang="babel" type="text/babel">
import photoItemAction from '@/components/photoSelector/action/photoItemAction'
export default {
  mixins: [photoItemAction],
  data: () => ({
    viewerPhoto: null,
  }),
  methods: {
    // async open() {
    //   if(this.immediatelyPhoto) {
    //     this.viewerPhoto = window.eagleLodash.cloneDeep(this.immediatelyPhoto)
    //     await this.$helper.delay(0.2)
    //     this.$refs.photoViewer.active()
    //     return
    //   }
    //
    //   this.bottomSheetActive = true
    // },
    async openPreview() {
      const sizeListItem = this.photo.size_list.origin
      this.viewerPhoto = window.eagleLodash.cloneDeep(sizeListItem)
      await this.$helper.delay(0.2)
      this.$refs.photoViewer.active()
    },
    async choice(sizeListItem) {
      this.bottomSheetActive = false
      this.viewerPhoto = window.eagleLodash.cloneDeep(sizeListItem)
      await this.$helper.delay(0.2)
      this.$refs.photoViewer.active()
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
